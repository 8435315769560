@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

body {
    font-family: "Open Sans", sans-serif;
    top: 0 !important;
    font-size: 16px;
    background: #fff;
}

.h5,
h5 {
    font-weight: 500;
}

p {
    line-height: 25px;
    font-size: 16px;
}

a {
    font-family: "Open Sans", sans-serif;
}

.VIpgJd-ZVi9od-ORHb-OEVmcd.skiptranslate {
    display: none !important;
}

.goog-te-gadget-simple {
    margin: 0;
    background: transparent !important;
    color: #fff !important;
    border: none !important;
}

.goog-te-combo,
.VIpgJd-ZVi9od-ORHb *,
.VIpgJd-ZVi9od-SmfZ *,
.VIpgJd-ZVi9od-xl07Ob *,
.VIpgJd-ZVi9od-vH1Gmf *,
.VIpgJd-ZVi9od-l9xktf *,
.VIpgJd-ZVi9od-vH1Gmf-ibnC6b div,
.VIpgJd-ZVi9od-vH1Gmf-ibnC6b:link div,
.VIpgJd-ZVi9od-vH1Gmf-ibnC6b:visited div,
.VIpgJd-ZVi9od-vH1Gmf-ibnC6b:active div {

    color: #535353 !important;
}

.goog-te-gadget-simple:focus-visible {
    outline: none;
}

.goog-te-gadget-simple .VIpgJd-ZVi9od-xl07Ob-lTBxed,
.goog-te-gadget-simple .VIpgJd-ZVi9od-xl07Ob-lTBxed span {
    color: #fff !important;
}

.goog-te-gadget .goog-te-combo {
    margin: 1px 0;
    background: transparent;
    color: #fff !important;
    border: none;
}

.goog-te-gadget-simple option,
.goog-te-gadget .goog-te-combo option {
    margin: 4px 0;
    color: #000 !important;
    border: none !important;
}

blockquote {
    margin: 0 0 1rem;
    background: #ff800014;
    padding: 20px;
    border-radius: 20px;
    font-weight: 600;
}

.old-event iframe {
    aspect-ratio: 4 / 3.5;
    background: #fff;
    padding: 10px;
    width: 100%;
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
    .old-event iframe {
        aspect-ratio: 4 / 4.12;
    }

    .video-iframe iframe {
        aspect-ratio: 3/2.7;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1400px) {
    .old-event iframe {
        aspect-ratio: 4 / 3.62;
    }

    .video-iframe iframe {
        aspect-ratio: 3/2.37;
    }
}

@media screen and (min-width: 1400px) {
    .old-event iframe {
        aspect-ratio: 4 / 3.4;
    }

    .video-iframe iframe {
        aspect-ratio: 3/2.25;
    }
}

.goog-logo-link,
.goog-te-gadget-icon,
#google_translate_element img,
.VIpgJd-ZVi9od-l4eHX-hSRGPd,
.VIpgJd-ZVi9od-l4eHX-hSRGPd:link,
.VIpgJd-yAWNEb-L7lbkb {
    display: none !important;
}

.toptext {
    vertical-align: super;
    font-size: 60%;
}

@media screen and (max-width: 767px) {
    .toptext {
        vertical-align: unset;
        font-size: unset;
    }
}

.trans-section {
    margin: 100px;
}

.text2line {
    text-overflow: ellipsis;
    width: 100%;
    height: 50px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

a {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

.about p {
    font-optical-sizing: auto;
}

video {
    background-color: #000;
}

.shortsType {
    aspect-ratio: 16 / 9;
}

.video-pop {
    position: fixed;
    display: flex;
    align-items: center;
    z-index: 99;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #000000ad;

    .video-container {
        width: 60%;
        margin: 0 auto;
        background: #fff;
        border-radius: 12px;

        video {
            width: 100%;
            padding: 10px;
            background-color: #000;
        }

        button {
            position: absolute;
            border: none;
            padding: 3px 10px;
            display: flex;
            align-items: center;
            border-radius: 20px;
            top: 20px;
            right: 20px;
            vertical-align: middle;
            z-index: 99;

            svg {
                margin-right: 5px;
            }
        }
    }
}

.text-justify {
    text-align: justify;
}

*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.mv-icon {
    width: 100px;
}

.themeColor {
    color: #0AA555
}

.more-btn {
    position: relative;
    background: none;
    border: none;
}

.orangeColor {
    color: #FF7F00 !important;
}

.more-btn a:before {
    content: '';
    position: absolute;
    height: 50px;
    display: block;
    width: 50px;
    background: #FF7F00;
    z-index: 0;
    border-radius: 50%;
    top: 50%;
    left: 0px;
    transform: translateY(-50%);
    transition: 0.2s all linear;
}

.more-btn a:after {
    content: '';
    position: absolute;
    height: 0px;
    display: block;
    width: 0px;
    background: rgba(255, 127, 0, 0.278);
    z-index: 0;
    border-radius: 50%;
    top: 50%;
    left: 0px;
    transform: translateY(-50%);
    transition: 0.2s all linear;
    box-shadow: 11px 0 1px 10px rgba(255, 128, 0, 0.137);
}

.more-btn a {
    color: #000;
    text-decoration: none;
}

.more-btn a:hover::before {
    height: 35px;
    display: block;
    width: 35px;
}

.more-btn a:hover::after {
    height: 50px;
    display: block;
    width: 50px;
}

.more-btn a span {
    margin-right: 5px;
    padding-left: 20px;
    z-index: 1;
    position: relative;
}

.viewAllBtn {
    position: absolute;
    top: 0;
    right: 15px;
}

.viewAllBtn:hover a {
    text-decoration: underline;
}

.video-wrapper {
    position: relative;
    margin: 10px;

}

.video-wrapper:before {
    content: "";
    background: rgb(255, 255, 255);
    background: -moz-linear-gradient(180deg, rgba(255, 255, 255, 0) 40%, rgba(0, 0, 0, 1) 100%);
    background: -webkit-linear-gradient(180deg, rgba(255, 255, 255, 0) 40%, rgba(0, 0, 0, 1) 100%);
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 40%, rgb(0 0 0 / 78%) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#000000", GradientType=1);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    border-radius: 12px;
    left: 0;
    z-index: 2;
}

.video-wrapper:hover::before {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 40%, rgb(0 0 0) 100%)
}

.activeLink {
    width: 100%;
    border-radius: 10px;
    display: block;
    transition: 0.3s all ease-in-out;
    padding: 10px 0;
}

.activeLink:hover {
    padding: 10px;
    background: #0AA555;

    color: #fff !important;
}

.activeLink:hover svg path {
    stroke: #fff;
}

.activeLink svg {
    transition: 0.6s all ease-in-out;
}

.activeLink:hover svg {
    float: right;
}

.playbtn {
    position: absolute;
    top: 45%;
    left: 50%;
    z-index: 9;
    transform: translate(-50%, -50%);
}

.f-semibold {
    font-weight: 400;
}

.f-500 {
    font-weight: 500;

}

.titleFont.title {
    font-size: 30px !important;
    font-style: normal;
    font-weight: 600;
    text-transform: capitalize !important;
}

.about-dis {
    font-size: 16px;
    line-height: 30px;
    color: #575757;
    font-weight: 300;
}

.f-18 {
    font-size: 18px;
}

.color-light-gray {
    color: #575757;
}

.video-wrapper figcaption {
    position: absolute;
    bottom: 10px;
    z-index: 9;
    font-size: 13px;
    left: 15px;
    color: #fff;
}

.video-wrapper figure {
    margin-bottom: 0;
    position: relative;
    overflow: hidden;
    border-radius: 12px;
}

.video-wrapper figure img {
    border-radius: 12px;
    object-fit: cover;
    aspect-ratio: 4/3;
}

.categoryList,
.noncategoryList {
    display: flex;
    padding-left: 0;
}

.noncategoryList li {
    list-style: none;
    list-style: none;
    font-size: 16px;
    border-radius: 33px;
    margin-right: 30px;
}

.categoryList li {
    list-style: none;
    list-style: none;
    padding: 10px 30px;
    font-size: 16px;
    border-radius: 33px;
    cursor: pointer;
}

.live {
    color: red;
}

.live::before {
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background-color: red;
    display: inline-block;
    margin-right: 5px;
}

.categoryList li.active {
    background: #0AA555;
    color: #fff;
}

.white-color {

    color: #fff;
}

.banner-content {
    position: absolute;
    color: #fff;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    padding: 50px 20%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 30;
    display: flex;
    align-items: center;
}

.action-button {
    padding: 10px 30px;
    border: none;
    position: relative;
    border-radius: 30px;
    margin-top: 30px;
    overflow: hidden;
    display: inline-block;
    transition: transform 0.4s cubic-bezier(0.1, 0, 0.3, 1);
}

.action-button.small-btn {
    padding: 9px 26px;
}
.notification-box{
    max-width: 600px;
}
.action-button i {
    z-index: 9;
    position: relative;
    font-style: normal;
}

.action-button span {
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background: #0AA555;
    border-radius: 0.85rem;
    overflow: hidden;
    transition: transform 0.4s cubic-bezier(0.1, 0, 0.3, 1);
}

.action-button:hover span {
    transform: scale3d(1.2, 1.2, 1);
}

.action-button span::after,
.action-button span::before {
    content: '';
    position: absolute;
    background: #fff;
}

.action-button span::after {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.3s;
}

.action-button span::before {
    width: 110%;
    height: 0;
    padding-bottom: 110%;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    transform: translate3d(-50%, -50%, 0) scale3d(0, 0, 1);
}

.action-button:hover span:after {
    opacity: 1;
    transition-duration: 0.01s;
    transition-delay: 0.3s;
}

.action-button:hover span::before {
    transition: transform 0.4s cubic-bezier(0.1, 0, 0.3, 1);
    transform: translate3d(-50%, -50%, 0) scale3d(1, 1, 1);
}

.action-button {
    color: #fff !important;
    border: 2px solid #0AA555;
}

.action-button:hover {
    border: 2px solid #0AA555;
}

.action-button:hover i {
    color: #0AA555
}

.submit-btn {
    outline: none;
    cursor: pointer;
    border: none;
    background-color: transparent;
    text-align: center;
    color: white;
}

.submit-btn:hover {
    color: #0AA555
}

.banner-content h1 {
    line-height: 1.5;
    font-family: "Open Sans", sans-serif;
}

.banner-content h5 {
    font-weight: 200;
    text-transform: uppercase;
    font-size: 18px;
}

.banner .banner-content:after {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgb(0 0 0 / 61%);

    top: 0;
    left: 0;
    z-index: -1;
}

.owl-theme .owl-nav [class*=owl-],
.owl-theme .owl-nav {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    border-radius: 42px !important;
    transition: 0.3s all linear;
}

.owl-theme .owl-dots {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
}

.iframe4-3 iframe {
    width: 100%;
    aspect-ratio: 4/2.5;
}

.about .owl-theme .owl-dots {
    bottom: -15px;
}

.owl-theme .owl-nav img {
    width: 23px;
}

.owl-theme .owl-nav .owl-next {
    right: 0;
    left: auto;
    border-radius: 42px;
    width: 50px;
}

.owl-theme .owl-nav .owl-prev {
    right: auto;
    left: 0;
    border-radius: 42px;
    width: 50px;
}

.owl-theme .owl-nav [class*=owl-]:hover {
    background: #ffffff !important;
    color: #FFF;
    text-decoration: none;
    border: 6px solid #0aa555;
}

.f-600 {
    font-weight: 600;
}
.large-text {
    font-family: sans-serif;
    font-optical-sizing: auto;
    color: black;
    font-size: 72px;
    -webkit-text-stroke: 1px black;
    -webkit-text-fill-color: white;
    opacity: 0.1;
    font-weight: 600;
    position: absolute;
    top: 12px;
    z-index: 1;
    width: 100%;
}

.inner-container-small {
    position: relative;
    display: inline-block;
    width: 100%;
}

/* --event-- */

.card1 {
    position: relative;
    cursor: pointer;
}

.card-content {
    padding: 15px;
}

.card-content .small {
    font-size: .875em;
    color: #787878;
    display: flex;
    align-items: start;
    text-align: start !important;
    line-height: 20px;
    margin: 0;
}

.card-content .small i {
    margin-right: 5px
}

.card1:hover {
    transition: all .5s ease;
    transform: translateY(-.25rem);
}

.card1 img {
    width: 100%;
    max-width: 100%;
}

.card-text {
    color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
    padding: 0 30px;
}

.box-shadow-0 {
    box-shadow: none !important;
}

.card-text img {
    width: auto;
    max-width: 100%;
    margin: 0 auto;
    text-align: center;
    display: block;
    height: 70px;
}

b,
strong {
    font-weight: bold;
}

.card-text p {
    margin-top: 17px;
    font-size: 18px;
    text-align: center;
}

.card {
    position: relative;
    border: none !important;
    overflow: hidden;
    box-shadow: 0 0 15px #00000040;
}

.card1:before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgb(0 0 0 / 50%);
    /* opacity: 0.3; */
    transition: opacity 0.3s;
    z-index: 0;
    border-radius: 50%;
    width: 100%;
    max-width: 100%;
}

.card1:hover::before {
    background: rgb(196, 51, 51);
    animation-duration: 0.5s;
    animation-name: rotateAnimation;
    animation-iteration-count: infinite;
    background: -moz-linear-gradient(180deg, rgba(196, 51, 51, 0.7736344537815126) 0%, rgba(186, 12, 201, 0.7428221288515406) 100%);
    background: -webkit-linear-gradient(180deg, rgba(196, 51, 51, 0.7736344537815126) 0%, rgba(186, 12, 201, 0.7428221288515406) 100%);
    background: linear-gradient(180deg, rgba(196, 51, 51, 0.7736344537815126) 0%, rgba(186, 12, 201, 0.7428221288515406) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#c43333", endColorstr="#ba0cc9", GradientType=1);
}

@keyframes rotateAnimation {
    0% {
        transform: rotate3d(0 0 0 0deg);
    }

    100% {
        transform: rotate3d(0 0 0 360deg);

    }
}

.card1:after {
    position: absolute;
    left: calc(50% - 16px);
    top: calc(50% - 16px);
    color: #fff;
    font-size: 32px;
    font-weight: 600;
    line-height: 1;
    opacity: 0;
    transition: opacity 0.3s;
    z-index: 1;
    width: 100%;
    max-width: 68%;
}

.card-shadow {
    box-shadow: 0 0 15px 0 rgb(0 0 0 / 25%);
    padding: 20px;
}
.card-shadow{
    transition: all 0.3s;
}

.card-body h3 {
    padding: 11px 0 0;
    font-size: 16px;
}

.card-body .mb-2 {
    margin-left: 15px;
}

.card-data {
    right: 8px;
    top: 0;
    position: absolute;
    background: #fff;
    border-radius: 0px 0px 15px 15px;
    height: auto;
    width: 62px;
    text-align: center;
    padding-bottom: 10px;
    padding-top: 2px;
    border-radius: 0;
}

.card-data span {
    color: #434343 !important;
    font-weight: 600;
    font-size: 14px
}

.card-data h6 {
    font-size: 30px;
    margin: 0
}

.card-body figure {
    position: relative;
    margin-bottom: 0;
}

.card-body figure img {
    width: 100%;
}

.card-body figure::after {
    content: "";
    background: rgb(255, 255, 255);
    background: -moz-linear-gradient(180deg, rgba(255, 255, 255, 0) 40%, rgba(0, 0, 0, 1) 100%);
    background: -webkit-linear-gradient(180deg, rgba(255, 255, 255, 0) 40%, rgba(0, 0, 0, 1) 100%);
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 40%, rgb(0 0 0 / 78%) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#000000", GradientType=1);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.w-80 {
    max-width: 80%;
}

.event-name {
    position: absolute;
    bottom: 15px;
    font-size: 19px;
    left: 15px;
    z-index: 9;
    color: #fff;

}

.event-name p {
    margin-bottom: 0;
}

iframe {
    max-width: 100%;
}

.inner-banner {
    position: relative;
}

.inner-banner.withoutbg {
    min-height: 400px;
}

.inner-banner figure img {
    width: 100%
}

.inner-banner::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.61);
    top: 0;
    left: 0;
}

.inner-banner .breadcrumb-wrap {
    position: absolute;
    top: 64%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9;

    transform: translate(-50%, -50%);
}

.inner-banner .breadcrumb-wrap ol {
    justify-content: center;
}

.inner-banner .breadcrumb-wrap ol li,
.inner-banner .breadcrumb-wrap ol li a {
    text-transform: capitalize;
    font-size: 14px;
    font-weight: 300;
    color: #fff;
}

.inner-banner .breadcrumb-wrap ol {
    margin-bottom: 0;
}

.breadcrumb-item + .breadcrumb-item::before {
    color: #fff;
}

.inner-banner .breadcrumb-wrap h2 {
    line-height: 1.5;
    text-align: center;
    color: #fff;
    font-family: "Open Sans", sans-serif;
}

.inner-banner figure {
    margin-bottom: 0;
}

.section {
    padding: 50px 0!important;
}

.mb-30 {
    margin-bottom: 30px;
}

.custom-list li {
    list-style: none;
    position: relative;
    padding-left: 30px;
    line-height: 30px;
    margin-bottom: 15px;
}


.custom-list li::before {
    background: url(../../assets/images/check-square.svg);
    content: "";
    height: 27px;
    width: 20px;
    display: inline-block;
    background-repeat: no-repeat;
    position: absolute;
    left: 0;
    top: 0;

}

.catlist figure,
.catlist figure img,
.catlist p {
    margin-bottom: 0;
    padding: 0;
}

.catlist figure img {
    max-width: 100%;
    transition: 0.3s all linear;
}

.catlist {
    align-items: center;
    cursor: pointer;

    figure::before {
        position: absolute;
        border: 4px solid #f9c555;
        width: 80%;
        height: 80%;
        left: 50%;
        transition: 0.3s all linear;
        content: "";
        transform: translate(-50%, -50%) scale(0.5);
        top: 50%;
        opacity: 0;
        visibility: hidden;
    }

    figcaption {
        position: absolute;
        left: 0;
        bottom: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: column;
        z-index: 9;

        p {
            background: #f9c555;
            padding: 8px 22px;
            width: 100%;
            text-align: center;
            font-weight: 600;
        }

        a {
            display: none;
            transition: 0.3s all linear;
            font-weight: 600;
        }
    }

    &:hover {
        img {
            transform: scale(1.1);
        }

        figure:before {
            opacity: 1;
            visibility: visible;
            transform: translate(-50%, -50%) scale(1);
            z-index: 99;
        }

        figcaption {
            position: absolute;
            left: 0;
            bottom: 36px;
            border: none;
            width: 80%;
            height: 80%;
            left: auto;
        }

        p {
            background-color: rgba(0, 0, 0, 0.56);
            color: #ffff;
        }

        a {
            display: block;
            background-color: #f9c555;
            padding: 5px 10px;
        }
    }
}

.bg-img {
    background: url('../images/bg-img.jpg');
    background-size: cover
}

.owneventcard {
    padding: 0;
    margin-bottom: 0;
    align-items: center;
    text-align: center;
    font-weight: 600;

    img {
        width: 100%;
        margin: 0 auto;
        display: block;
    }

    p {
        margin-bottom: 0;
        position: absolute;
        background: #ffffff9e;
        padding: 10px 15px;
        transform: translate(-50%, -50%);
        left: 50%;
        top: 50%;
        display: inline-block;
        width: 80%;
    }
}

.cardwithIcon {
    .icon-bg {
        width: auto;
        height: auto;
        background-repeat: no-repeat;
        background-position: 0 0;
        display: block;
        margin: 30px auto 20px;

        img {
            height: 150px;
            overflow: hidden;
            border-radius: 120px;
        }
    }

    &:nth-child(2) .icon-bg {
        background-position: -251px 0;
    }

    &:nth-child(3) .icon-bg {
        background-position: -123px 0;
    }
}

.cat-detail {
    text-align: center;
    padding: 0 20px 20px;

    h4 {
        font-size: 20px;
        font-weight: 600;
    }

    p {
        font-weight: 600;
        margin-bottom: 5px;
    }

    ul {
        padding: 0;
        margin: 0 0 10px 0;
        display: flex;
        flex-flow: wrap;
        justify-content: center;

        li {
            list-style: none;
            border: 2px solid #0aa555;
            padding: 2px 10px;
            border-radius: 12px;
            margin: 0 3px 5px;
            font-size: 14px;
            color: #0aa555
        }
    }
}

.banner-content {
    justify-content: center !important;
}

@media screen and (min-width: 1200px) {

    .h2,
    h2 {
        font-size: 2.5rem !important;
    }
}

@media screen and (max-width: 1199px) {
    body.oppenned {
        position: fixed !important;
        left: 0;
        right: 0;
    }

    body.oppenned .mobile-menu {
        width: 360px !important;

    }
}

@media screen and (min-width: 992px) {
    .w-70 {
        max-width: 70%;
    }

}

@media screen and (max-width: 991px) {
    .large-text {
        font-size: 42px;
    }

    .inner-banner .breadcrumb-wrap {
        width: 80%;
        margin: 0 auto;
    }

    h2 {
        font-size: calc(1rem + 0.9vw);
    }

    .titleFont.title {
        margin-top: 20px !important;
    }
}

@media (max-width: 767.98px) {
    body {
        font-size: 14px;
    }

    .f-18 {
        font-size: 16px;
    }

    .card-text p {
        padding: 0 15px;
    }

    .action-button {
        padding: 5px 19px;
        font-size: 13px;
    }

    .navbar,
    footer {
        width: auto;
    }
}

@media screen and (max-width: 767px) {
    .mv-icon {
        width: 60px;
    }

    .w-70P {
        width: 100%;
    }

    .video-pop {
        transform: rotate(90deg);
        transform-origin: center center;
        width: 100vh;
        height: 100vw;
        background: #000;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(90deg);
    }

    .video-pop .video-container {
        width: 98%;
    }

    .banner-content h1 {
        font-size: 18px;
    }

    .banner-content h5 {
        font-size: 18px;
    }

    .banner-content {
        padding: 50px 5%;
        justify-content: center;
    }

    .action-button {
        padding: 10px 25px;
    }


    .large-text {
        display: none;
    }

    .about:after {
        display: none !important;
    }
}

@media screen and (max-width: 549px) {

    .f-18,
    p,
    h4 {
        font-size: 14px;
    }

    .h3,
    h3 {
        font-size: calc(1rem + .3vw);
    }

    body.oppenned .mobile-menu {
        width: calc(100% - 106px) !important
    }

    .categoryList li {
        font-size: 13px;
    }

    .banner .owl-carousel .owl-item img {
        height: 242px;
        object-fit: cover;
    }

    .inner-banner figure img {
        width: 100%;
        object-fit: cover;
    }

    .inner-banner.large figure img {
        width: 100%;
        height: 259px;
        object-fit: cover;
    }

    .banner-content h1 {
        font-size: 14px;
    }

    .banner-content h5 {
        font-size: 12px;
    }

    .banner-content {
        padding: 50px 5%;
    }

    .action-button {
        padding: 10px 25px;
    }

    .section {
        padding: 30px 0;
    }
}

@media screen and (max-width: 360px) {

    body.oppenned .mobile-menu {
        width: 230px !important
    }
}

@media screen and (max-width: 766px) {
    .titleFont.title {
        font-size: 20px !important;
    }
}