.ambass-card {
    position: relative;
}

.ambass-text {
    position: absolute;
    bottom: 16px;
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    background-color: rgba(0, 0, 0, 0.4);
    padding: 15px;
    border-radius: 25px;
    width: calc(100% - 30px);
    left: 50%;
    transform: translateX(-50%);
    max-height: 54px;
    min-height: 84px;
}

.ambass-text p {
    color: #fff;
    line-height: 20px;
    font-size: 13px;
    margin: 0;

}
.ambass-text span{
    display: inline-block;width: 100%;
}
.ambass-card img {
    border-radius: 30px;
    width: 100%;
}
