.subscription-container {
    background-color: #0aa5550f; /* Adjust as needed */
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.subscription-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.toggle-switch {
    display: flex;
}

.main-price {
    display: flex;
    font-size: 22px;
    font-weight: 600;
    padding: 20px;
    align-items: center;
    justify-content: center;
}

.title-1 {
    font-size: 20px;
    font-weight: 600;
    width: 100%;
    background-color: #F44336;
    height: 25%;
    border-radius: 18px 18px 0px 0px;
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 40px 20px;
    color: #ffffff;
}

.main-item {
    margin: 10px 0;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.toggle-button {
    background-color: #0aa5550f; /* Default button color */
    border: none;
    border-radius: 24px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.toggle-button.active {
    background-color: #333; /* Active button color */
    color: #fff;
}

.plan {
    width: 100%;
    background-color: #fff;
    border-radius: 22px 22px 8px 8px;
    text-align: center;
    flex: 1;
    border: 3px solid #ffffff;
    margin: 0 10px 30px;
    transition: transform .3s;
    height: 100%;
}

.plan:hover {
    /* transform: scale(1.05); */
    border: 3px solid #dc1818;

}

.no-datafound {
    background: #fff;
    padding: 20px;
    font-size: 16px;
    border-radius: 10px;
    text-align: center;
}
.memberDes{
    line-height: 20pt;
}