footer {
    background-color: #f8f8f8;
    padding-block: 34px;
    background-image: url('../../../assets/images/dot-grid.png.webp');
    color: #3b3b3b;
}


.rotate {
    width: 80px;
    animation: rotation 3s infinite linear;
    margin-bottom: 10px;
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}

footer h4 {
    padding: 16px 0;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
}

.footer-brand .footer-text {
    margin-block: 20px;
}

footer ul {
    padding: 0;
    margin: 0;
}

.footer .social-list a {
    color: #3b3b3b;
}


.footer-list-title {
    font-size: 16pt;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    color: #3b3b3b;
    margin-block-end: 0;
}

.quick-links {
    line-height: 18pt !important;
}

.footer-link {
    text-decoration: none;
    padding-block: 4px;
}

.footer-list i {
    position: absolute;
    content: "";
    left: 0px;
    top: 4px;
}

.contact-icon {
    margin-top: 8px;
}

.footer-contact-info li {
    position: relative;
    padding-left: 25px;
}

.footer-link:is(:hover,
:focus-visible) {
    /*color: var(--violet-blue-crayola);*/
}

.input-wrapper {
    position: relative;
    margin-block-start: 25px;
}

.input-field::placeholder {
    color: inherit;
}

.footer-list li {
    list-style: none;
}

.footer-list li a {
    text-decoration: none;
    color: #3b3b3b;
}

.halflist li {
    width: 50%;
    float: left;
}

footer {
    position: relative;
}

.footer-link {
    color: #3b3b3b !important
}

.bg-img {
    position: absolute;
    top: 0;
    left: 0
}

.copy-right {
    padding: 14px 0;
    color: #3b3b3b;
}

.bg-img2 img {
    width: 100%;
    height: 100px;
}

.bg-img2 {
    position: absolute;
    right: 0;
    bottom: -52px;
}

.animated_rainbow_1 {
    font-size: 16px;
    color: #000;
    font-weight: 600;
}

.social-list {
    display: flex;
    padding: 0;
    gap: 5px;
}

.social-list > li > a {
    list-style: none;
    transition: 0.3s;
}

.contact-div {
    display: flex;
    gap: 15px;
    line-height: 20px;
    flex-direction: row;
}

.social-list a {
    color: #3b3b3b;
    font-size: 20px;
    padding: 5px 10px;
}

.tab-item {
    cursor: pointer;
    text-decoration: none;
    color: white;
    transition: 0.3s;
}

.scrolled-tab-item {
    cursor: pointer;
    text-decoration: none;
    color: black;
    transition: 0.3s;
}

#facebook-icon > a:hover {
    background-color: #090579;
    color: white;
}

#twitter-icon > a:hover {
    background-color: #000;
    color: white;
}

#instagram-icon > a:hover {
    background: radial-gradient(circle at 33% 100%, #fed373 4%, #f15245 30%, #d92e7f 62%, #9b36b7 85%, #515ecf);
    color: white;
}

#linkdin-icon > a:hover {
    background-color: #0a66c2;
    color: white;
}

#youtube-icon > a:hover {
    background-color: #ff0000;
    color: white;
}

.contact-title {
    margin-left: 25px;
}


@keyframes animatedBackground_a {
    0% {
        background-position: 0 0
    }
    100% {
        background-position: -500px 0
    }
}

@media screen and (max-width: 767px) {
    .halflist li {
        width: 100%;
    }

}


@media screen and (min-width: 960px) {
    .center-div {
        order: 2;
    }

    .left-div {
        order: 1;
    }

    .right-div {
        order: 3;
    }
}

@media screen and (max-width: 992px) {
    .contact-title {
        margin-left: 30px;
    }
}

@media screen and (max-width: 575px) {
    .contact-title {
        margin-left: 0;
    }
}

.copy-right {
    padding: 20px 0;
    background-color: #f8f9fa; /* Example background color */
}

.text-primary {
    color: #007bff; /* Example primary color */
}

.footer-link {
    text-decoration: none; /* Remove underline */
}

.footer-link:hover {
    /* text-decoration: underline; Underline on hover */
}

.footer-text {
    font-weight: 500; /* Adjust the value for the desired boldness */
}
  