.vision-list {
    span {
        font-size: 22px;
        border-radius: 50%;
        background-color: #ff7f00;
        color: #fff;
        width: 38px;
        height: 38px;
        display: inline-block;
        text-align: center;
        line-height: 38px;
        margin-right: 12px;
    }

    h4 {
        display: inline-block;

    }

    p {
        margin-left: 50px;
    }
}

.ancient-text {
    font-size: calc(1rem + .3vw);
}