.contact-box {
    padding: 30px;
}

.address {
    background: #F3F5FD !important;
}

.email {
    background: #FFF0F2 !important;
}

.number {
    background: #FFFAD0 !important;
}

.action-button:hover .submit-btn {
    color: #0AA555;
}

.send-btn {
    background: #0AA555 !important;
    color: #fff !important;
    border-radius: 1px !important
}

.icons {
    font-size: 40px
}

.form-control {
    border-radius: 1px !important
}

.h-100 {
    height: 100%;
}

@media screen and (max-width: 991px) {
    .loctaion iframe {
        height: 300px !important;
        margin-top: 20px;
    }

}