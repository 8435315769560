
.donate-section {
    background: #fff;
    min-width: 400px;
    border-radius: 20px;
    padding: 30px;
}

.post h2 {
    font-size: 38px !important
}

.scan {
    height: 100%;
    max-height: 400px;
    border-radius: 20px;
}

.details {
    display: flex;
    justify-content: center;
    align-items: center;
}

dl {
    margin-bottom: 0;
}

dt {
    font-weight: bold;
    margin-bottom: 15px;
}

dd {
    margin-left: 0;
    margin-bottom: 15px;
    font-size: 16px !important
}

.links {
    margin-top: 30px;

    ul {
        display: flex;
        list-style: none;
        justify-content: center;
        gap: 30px;
        padding: 0;

        a {
            color: blue !important;
            text-decoration: underline;
        }
    }
}

.register {
    background: #FF7F00 !important;
    border: none;
    outline: none;
    color: #fff !important;
    border-radius: 10px !important;
    padding: 12px !important;
}

.custom-box-shadow {
    box-shadow: 0px 0px 45px 0px rgb(255 127 0 / 20%);
}