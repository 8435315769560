.marquee-container {
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    background-color: #f8f8f8;
    padding: 10px 0;
    margin-bottom: 30px;
}

.marquee-content {
    display: inline-flex;
    animation: scroll 30s linear infinite;
}

@keyframes scroll {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-50%);
    }
}

.logo {
    max-height: 150px;
    margin: 0 20px;
}