section {
    position: relative;
}

.obj {
    position: absolute
}

span.obj1.obj {
    width: 100px;
    top: 16px;
}

span.obj2.obj {
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: -1;
    opacity: 0.5;
}

.light-bg-image1 {
    background: url(../../../assets/images/mobj1.png);
    background-size: cover;
}

.light-bg-image2 {
    background: url(../../../assets/images/bg-img.jpg);
    background-size: cover
}

.categories-list {
    ul {
        display: flex;
        flex-flow: wrap;

        li {
            max-width: 33.3%;
            list-style: none;

            img {
                max-width: 150px;
            }
        }
    }

}

.cardwithIcon .card {
    height: 100%;

}

.cardwithIcon.full-width .card {
    display: flex;
    align-items: center;
    flex-flow: row;
    padding: 10px 20px;

    .cat-detail {
        display: flex;
        align-items: center;
        flex-flow: row;
        justify-content: space-between;
        width: 100%;

        .basic-detail {
            width: calc(100% - 250px);
            text-align: left;

            ul.description {
                padding: 0;

                li {
                    list-style: none;
                    border: none;
                    padding: 0;
                    border-radius: 12px;
                    margin: 0;
                    font-size: 14px;
                    color: #808080;
                }
            }

            ul {
                padding: 0;
                justify-content: start;
            }
        }
    }

    .icon-bg {
        margin: 0;
        height: 100%;

        img {
            border-radius: 0;
        }
    }

    img {
        height: 100%;
        overflow: hidden;
        width: 250px;
        object-fit: cover

    }
}

@media screen and (max-width: 991px) {
    .cardwithIcon.full-width .card {
        display: flex;
        align-items: center;
        flex-flow: column;
        padding: 10px 20px;

        .cat-detail {
            display: flex;
            align-items: center;
            flex-flow: column;
            justify-content: space-between;

            .basic-detail {
                width: 100%;
                text-align: center;

                ul {
                    justify-content: center;

                    li {
                        text-align: justify;
                    }
                }
            }
        }

        .icon-bg {
            margin: 0 0 20px;

            img {
                border-radius: 120px;
                height: 150px;
                width: 150px;
            }
        }
    }
}