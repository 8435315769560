* {
    font-family: 'Open Sans', sans-serif;
}

.background-pic2 {
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(../../../assets/images/mark-logo.png);
    width: 100%;
}

.number_div {
    border-radius: 50%;
    background-color: #FF7F00;
    color: #fff;
    width: 38px;
    height: 38px;
    left: 10px;
    text-align: center;
    margin-right: 10px;
    line-height: 38px;
    flex-basis: 38px;
    position: absolute;
}

.history-table {
    width: 100%;
    border-collapse: collapse;
}

.history-table>tr>th {
    border: 1px solid black;
    padding: 8px;
    text-align: left;
}

.history-table>tr>td {
    border: 1px solid black;
    padding: 8px;
    text-align: left;
}

.history-table>tr>th {
    background-color: #f2f2f2;
}

.nav-pills .navtab {
    transition: 0.3s;
    border: 1px solid rgba(0, 0, 0, 0.121);
    border-radius: 5px;
    margin: 5px 0;
    display: flex;
    align-items: center;
    text-align: left;
    color: #000;
    font-size: 16px;
    font-weight: 500;
    position: relative;
    padding: 16px 16px 16px 60px;
}

.tab-content-img {
    max-width: 100%;
}

.nav-pills .navtab.active,
.nav-pills .show>.navtab {
    background-color: #FF7F00;
}

.nav-pills .navtab.active,
.nav-pills .show>.navtab>div>p {
    color: #fff;
}

.nav-pills .navtab.active .number_div,
.nav-pills .show>.navtab .number_div {
    color: #FF7F00;
    background-color: #fff;
}

.history-content>h3 {
    font-size: 1.75rem !important;
}