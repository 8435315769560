.medal-table-container {
    border: 2px solid rgba(255, 127, 0, 0.3);
    border-radius: 10px;
    box-shadow: 0 4px rgba(255, 127, 0, 0.2);
}

.collapse-table{
    width: 100%;
}
.collapse-table>tr{
    height: 30px!important;
}