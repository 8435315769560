.eventByCategories {
    display: flex;
    overflow: hidden;
}

.eventByCategories ul {
    padding: 0;
    flex: 0 0 auto;
    display: flex;
}

.eventByCategories ul li {
    list-style: none;
    text-align: center;
    float: left;
    font-size: 16px;
    padding: 10px 15px;
    width: 16%;
}

.eventByCategories ul li::before {
    content: "";
    width: 0;
    height: 100%;
    background-color: #000;
    display: inline-block;
    position: absolute;
    left: 0;
    top: 0;
    border: 1px solid #000;
}

.event-wrapper .card {
    background-color: transparent;
    border-radius: 0;

    img {
        /*--ratio: calc(412 / 290);*/
        width: 100%;
        /*aspect-ratio: 4/3;*/
        /*object-fit: cover;*/
    }
}

.card1 {
    position: relative;
    cursor: pointer;
    overflow: hidden;
    border-radius: 100%;
    --ratio: calc(300 / 300);
    width: 100%;
    aspect-ratio: 1 / var(--ratio);
}
.card-shadow {
    box-shadow: 0 0 15px 0 rgb(0 0 0 / 25%);
    padding: 20px;
}
