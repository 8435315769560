.App-header {
    width: 100%;
}

#cookie {
    width: 100%;
    padding: 10px 20px;
    background-color: var(--color1);
    position: fixed;
    z-index: 150;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
}

#cookie > div > p {
    margin: 0;
    line-height: 16px;
    text-align: start !important;
    color: white;
}

#cookie > div > p > a {
    color: white;
    text-decoration: underline;
}

#cookie > button {
    width: 100px;
    height: 35px;
    outline: none;
    background-color: white;
    border: none;
    cursor: pointer;
    border-radius: 2px;
}