.news-heading {
    font-size: 22px !important;
    font-weight: 600;
    margin-bottom: 20px;
}

.sidebar-wrap > h3 {
    font-size: 1.75rem;
}

.singleNews-wrap > a {
    display: flex;
}

.singleNews-wrap > a > figure > img {
    width: 80px;
}

.singleNews-wrap > a > figcaption > span {
    font-size: 14px;
    text-align: left;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    margin-left: 10px;
}