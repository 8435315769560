.banner {
    position: relative;
}

.home-banner-content {
    position: absolute;
    padding: 0 20%;
    color: #fff;
    width: 100%;
    text-align: center;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.carousel-indicators {
    z-index: 100;
}

.home-banner-content:after {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgb(0 0 0 /37%);
    top: 0;
    left: 0;
    z-index: -1;
}

.carousel-control-next, .carousel-control-prev {
    z-index: 100 !important;
}

.home-banner-content button {
    background: #0AA555;
    cursor: pointer;
    padding: 15px 40px;
    border: none;
    border-radius: 30px;
    margin-top: 10px;
}

.home-banner-content button > a {
    color: #fff !important;
    font-size: 16px !important;
    text-decoration: none;
}

.home-banner-image {
    position: relative;
    z-index: 1;
    width: 100%;
}

.home-banner-content > h1 {
    line-height: 1.5;
    font-family: "Open Sans", sans-serif;
    font-size: 45px;
    font-weight: 700;
}

.action-button {
    padding: 15px 40px;
    border: none;
    position: relative;
    border-radius: 30px;
    margin-top: 30px;
    overflow: hidden;
    display: inline-block;
    transition: transform 0.4s cubic-bezier(0.1, 0, 0.3, 1);
}

.action-button.small-btn {
    padding: 9px 26px;
}

.action-button i {
    z-index: 9;
    position: relative;
    font-style: normal;
}

.action-button span {
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background: #0AA555;
    border-radius: 0.85rem;
    overflow: hidden;
    transition: transform 0.4s cubic-bezier(0.1, 0, 0.3, 1);
}

.action-button:hover span {
    transform: scale3d(1.2, 1.2, 1);
}

.action-button span::after,
.action-button span::before {
    content: '';
    position: absolute;
    background: #fff;
}

.action-button span::after {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.3s;
}

.action-button span::before {
    width: 110%;
    height: 0;
    padding-bottom: 110%;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    transform: translate3d(-50%, -50%, 0) scale3d(0, 0, 1);
}

.action-button:hover span:after {
    opacity: 1;
    transition-duration: 0.01s;
    transition-delay: 0.3s;
}

.action-button:hover span::before {
    transition: transform 0.4s cubic-bezier(0.1, 0, 0.3, 1);
    transform: translate3d(-50%, -50%, 0) scale3d(1, 1, 1);
}

.action-button {
    text-decoration: none;
    color: #fff !important;
    border: 2px solid #0AA555;
}

.action-button:hover i {
    color: #0AA555
}

@media screen and (max-width: 766px) {
    .home-banner-content > h1 {
        font-size: 18px;
    }

    .action-button {
        padding: 5px 19px !important;
        font-size: 13px;
    }
}

@media screen and (max-width: 898px) {
    .home-banner-content {
        padding: 0 15%;
    }
}