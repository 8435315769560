@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.0.0-beta.4/css/lightgallery.css');
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.0.0-beta.4/css/lg-zoom.css');

.gallery-wrapper {
    display: flex;
    flex-flow: wrap;
}

.gallery-item {
    margin: 8px;
    width: calc(25% - 16px);
    display: inline-block;
    border-radius: 20px;
    overflow: hidden;
}

.gallery-item img {
    width: 100%;
}

ul {
    padding: 0;
    margin: 0;
}

.meta-data ul {
    display: flex;
    justify-content: center;
}

.meta-data ul li {
    list-style: none;
    margin: 0 10px;
}

.meta-data ul li img {
    margin-right: 5px;
}

.inner-banner.large {
    object-fit: cover;
    object-position: center;
    overflow: hidden;
}

.eventregister {
    background: #FF7F00;
    border-radius: 20px;
    box-shadow: 15px 15px rgba(255, 127, 0, 0.2);
    padding: 25px;
}

.eventregister ul {
    display: flex;
    justify-content: space-evenly;
}

.eventregister ul li {
    list-style: none;
    text-align: center;
    color: #fff;
}

.eventregister ul li span {
    display: block;
    margin-top: 10px;
    font-weight: 400;
    color: #fff;
}

.counter-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    row-gap: 12px;
    border: 2px solid #E9E9E9;
    border-left: 0;
    border-right: 0;
    font-size: 2rem !important;
    padding: 38px 0 50px;
}

.counter-wrap h2 {
    width: 30%;
    font-size: 1.8rem !important;
}

.counter-wrap .live-clock {
    width: calc(70%);
}

.counter-wrap .live-clock ul {
    list-style: none;
}

.counter-wrap ul {
    display: flex;
    justify-content: space-between;
}

.counter-wrap ul li {
    border: 2px solid rgba(255, 127, 0, 0.3);
    width: 18%;
    position: relative;
    height: 110px;
    border-radius: 20px;
    box-shadow: 15px 15px rgba(255, 127, 0, 0.2);
    padding: 25px;
}

.counter-wrap ul li:before {
    position: absolute;
    content: ":";
    font-size: 42px;
    right: -30%;
    color: #FF7F00;
}

.counter-wrap ul li:last-child:before {
    display: none;
}

.counter-wrap ul li b {
    font-size: 50px;
    color: #FF7F00;
    display: block;
    text-align: center;
    font-weight: 600;
    margin-top: -15px;
}

.counter-wrap ul li span {
    font-size: 16px;
    text-transform: uppercase;
    margin-top: -15px;
    display: block;
    text-align: center;
    font-weight: 600;
    color: #FF7F00;
}

.category-wrap {
    position: relative;
    border-radius: 20px;
}

.category-wrap:before {
    content: "";
    border-radius: 20px;
    position: absolute;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 40%, rgba(0, 0, 0, 0.78) 100%);
    height: 100%;
    width: 100%;
}

.category-wrap img {
    max-width: 100%;
}

.category-wrap figcaption {
    position: absolute;
    bottom: 50px;
    left: 30px;
    color: #fff;
    font-size: 30px;
    font-family: "Playfair Display", serif;
}

.category-wrap .circle-btn {
    position: absolute;
    bottom: -42px;
    background: #f3f3f3;
    left: 50%;
    transform: translateX(-50%);
    color: #FF7F00;
    width: 90px;
    height: 90px;
    border-radius: 100%;
    text-align: center;
    line-height: 70px;
    font-size: 24px;
    border: 9px solid #fff;
}

.location-detail table td {
    padding: 7px 0;
    font-weight: 600;
    vertical-align: text-bottom;
    border: none;
}

.location-detail table td:first-child {
    min-width: 200px;
}

.eventCategoryList {
    background: #fff;
    padding: 20px;
    margin: 10px;
    width: calc(100% / 4 - 20px);
    box-shadow: 0 0 15px -1px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
}

.eventCategoryList > h3 {
    text-align: center;
    font-size: 14pt;
    margin-bottom: 10px;
    font-weight: 600;
}

.eventCategoryList ul {
    list-style: disc !important;
    padding-left: 15px;
}

.eventCategoryList ul::marker {
    color: black !important;
}

@media screen and (max-width: 1024px) {
    .counter-wrap {
        flex-flow: wrap;
    }

    .counter-wrap h2 {
        width: 100%;
        font-size: 1.5rem !important;
    }

    .counter-wrap .live-clock {
        width: 100%;
    }
}

@media screen and (max-width: 767px) {
    .location-detail table td:first-child {
        width: auto;
    }

    .meta-data ul {
        flex-flow: wrap;
    }

    .meta-data ul li {
        width: 100%;
        margin-bottom: 10px;
    }

    .counter-wrap ul li {
        padding: 10px 0;
        border: none;
        margin-top: 5px;
        box-shadow: none;
    }

    .counter-wrap h2 {
        font-size: 1.3rem !important;
    }

    .counter-wrap ul li:before {
        right: -37%;
        top: 0px;
    }

    .counter-wrap ul li b {
        font-size: 27px;
    }

    .counter-wrap ul li span {
        margin-top: 5px;
        font-size: 11px;
    }

    .eventCategoryList {
        width: calc(50% - 20px);
    }
}

.game-box > div {
    padding: 15px;
    border-radius: 10px;
    background-color: white;
    /*box-shadow: 0 3px 8px 1px #c7c7c7;*/
    box-shadow: 10px 10px rgba(255, 127, 0, 0.2);
    border: 2px solid rgba(255, 127, 0, 0.3);
    height: 100%;
}

/*.game-box > div:before {*/
/*    width: 100%;*/
/*    height: 100%;*/
/*    position: absolute;*/
/*    top: 10px;*/
/*    left: 0;*/
/*    background-color: ;*/
/*}*/

.game-box > div > h4 {
    font-size: 20px;
    margin-bottom: 10px;
    font-weight: 700;
    display: flex;
    align-items: center;
    gap: 5px;
    /*color: #FF7F00;*/
}

.game-box > div > div {
    /*background-color: #f2f2f2;*/
    background-color: rgba(255, 127, 0, 0.1);
    border-radius: 5px;
    margin-bottom: 5px;
    padding: 8px 5px;
}

.game-box > div > div > p {
    margin-bottom: 2px;
}

.game-box > div > div > p > span {
    font-weight: 600;
}