.founder-main {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.founder-main .picture {
    display: flex;
    flex-direction: column;
}

.founder-main .picture img {
    width: 320px;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
}

.founder-info {
    width: 320px;
    background-color: #0aa555;
    color: white;
    padding: 10px 0;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    margin-bottom: 20px;
}

.founder-info h3 {
    font-size: 1.75rem;
}

.founder-text {
    padding: 0 20px;
}

@media screen and (max-width: 768px) {
    .founder-main {
        flex-direction: column;
        align-items: center;
    }

    .founder-text {
        padding: 0;
    }
}