:root {
    --color1: #0aa555;
}

.App-header {
    z-index: 999;
    top: 0;
    position: fixed;
    width: 100%;
}

.app-bar {
    background-color: var(--color1);
}

.social-link-container {
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    flex-wrap: wrap;
    color: white;
}

.social-link-container > a {
    color: white;
    padding: 6px 10px;
}

.select-language {
    cursor: pointer;
    display: flex;
    width: fit-content;
    color: white;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px solid white;
    border-radius: 15px;
    font-size: 10pt;
    padding: 0 6px;
    gap: 5px;
}

.dropdown-menu > li a {
    padding-top: 8px;
    padding-bottom: 8px;
}

.nav-item a {
    color: white;
    font-size: 15px;
}

.nav-item ul li a {
    color: #464646;
}

.dropdown-menu {
    border-radius: 0 !important;
    animation: 0.5s slideup;
}

.nav-link {
    color: white !important;
}

/*.nav-link:checked {*/
/*    background-color: white !important;*/
/*}*/

/*.nav-link:active {*/
/*    background-color: white !important;*/
/*}*/

/*.nav-link:focus {*/
/*    background-color: white !important;*/
/*}*/

.nav-link:hover {
    transition: all 0.3s ease-in-out;
    color: #0AA555 !important;
}

@keyframes slideup {
    from {
        transform: translateY(10%);
    }

    to {
        transform: translateY(0);
    }
}

.dropdown-item {
    position: relative;
    min-width: 280px !important;
    border-bottom: 1px solid rgb(204 204 204 / 22%);
}

.nav-item:hover .dropdown-menu {
    display: block;
    opacity: 1;
}

.dropdown-item:before {
    position: absolute;
    content: '';
    width: 0;
    height: 100%;
    left: 0;
    background-color: rgb(10 165 85 / 10%);
    top: 0;
    transition: 0.3s all linear;
}

.dropdown-item.active:before,
.dropdown-item:active:before,
.dropdown-item:hover:before {
    width: 100%;
}

.dropdown-item.active,
.dropdown-item:active,
.dropdown-item:hover {
    background-color: transparent;
    color: #0AA555 !important
}

.header-section {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.scrolled {
    background-color: white;
    transition: 0.3s;
}

.header-section-scrolled {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    transition: 0.3s;
}

.goog-te-gadget {
    width: 130px;
    overflow: hidden;
}

.goog-te-combo {
    outline: none;
    width: 125px;
}

.header-section-image {
    padding: 12px 8px;
    background-color: white;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}

.header-section-account-register {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 3px;
}

.header-section-account-register > button {
    background-color: transparent;
    outline: none;
    border: none;
    cursor: pointer;
    height: 40px;
    padding: 0 20px;
    border-radius: 6px;
    font-size: 12pt;
    transition: 0.3s;
    color: white;
}

.scrolled-btn {
    color: black !important;
}

.header-section-account-register > button:hover {
    background-color: var(--color1);
}

.line {
    width: 1px;
    background-color: white;
    height: 25px;
}

.scrolled-line {
    width: 1px;
    background-color: black;
    height: 25px;
}

.header-section-tabs {
    display: flex;
    list-style: none;
    align-items: center;
    gap: 20px;
    margin: 0 !important;
}

.dropdown-menu, .submenu-menu {
    min-width: 260px;
}

.dropdown-menu .submenu-menu {
    display: none;
    padding: 0;
}
.dropdown-menu .submenu-menu li{
    list-style: none;
}

.dropdown-menu > li:hover .submenu-menu {
    left: 100%;
    top: 0;
    display: block;
    position: absolute;
    background: #fff;
    padding: 0;
}

.scrolled-item {
    color: black !important;
}

.facebook-icon:hover {
    background-color: #090579;
}

.twitter-icon:hover {
    background-color: #000;
}

.twitter-icon:hover > svg {
    color: #fff;
}

.instagram-icon:hover {
    background: radial-gradient(circle at 33% 100%, #fed373 4%, #f15245 30%, #d92e7f 62%, #9b36b7 85%, #515ecf);
}

.linkedin-icon:hover {
    background-color: #0a66c2;
}

.youtube-icon:hover {
    background-color: #ff0000;
}


.css-j400xw-MuiPaper-root-MuiDrawer-paper {
    background-color: #000 !important;
}

.mobile-menu {
    display: flex;
    flex-direction: column;
    background-color: black !important;
}

.mobile-menu a {
    padding: 15px 25px;
}

.mobile-item {
    color: white;
    text-decoration: none;
}

.accordion {
    border: none;
    background-color: transparent !important;
    --bs-accordion-bg: transparent;
}

.accordion {
    --bs-accordion-bg: transparent !important;
    --bs-accordion-border-color: none !important;
}

.accordion-button {
    color: white !important;
    padding: 15px 25px !important;
}

.accordion-button:focus {
    box-shadow: none !important;
}

.accordion-button:not(.collapsed) {
    background-color: transparent !important;
}

.accordion-button::after {
    background-image: url("../../../assets/images/arrow.png") !important;
    height: 15px !important;
    width: 20px !important;
}