p {
    color: #272727;
    font-size: 14px;
    line-height: 24px;
    text-align: justify;
}

.lh-base {
    line-height: 1.5 !important;
}

.mt-3 {
    margin-top: 1rem !important;
}

.blockquote {
    font-size: 16px !important;
    margin: 0 0 1rem !important;
    padding: 20px;
    border-radius: 20px;
    font-weight: 600;
    background: rgba(255, 128, 0, 0.0784313725);
}

.blockquote > p {
    color: #272727;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
}

.categoryList {
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
}

.about-circle:after {
    content: "";
    background: rgba(217, 217, 217, .431372549);
    --ratio: 1;
    width: 30%;
    max-width: 480px;
    aspect-ratio: 1 / 1;
    border-radius: 100%;
    top: 50px !important;
    display: inline-block;
    right: 0;
    position: absolute;
    z-index: 0;
}

.categoryList > li {
    padding: 10px 30px;
    border-radius: 35px;
    cursor: pointer;
}

.active-category {
    background-color: var(--color1);
    color: white;
}

.inner-container-small {
    display: inline-block;
    position: relative;
    width: 100%;
    margin: 30px 0;
}

.event-wrapper .card {
    background-color: transparent;
    border-radius: 0;

    img {
        width: 100%;
        aspect-ratio: 4/2;
    }
}

.card-body {
    padding: 0 !important;
}

.card-text p {
    padding: 0 25px;
    color: white;
}

.carousel-video-wrapper {
    position: relative;
    margin: 10px;
    height: 170px;
    overflow: hidden;
    border-radius: 12px;
}

.carousel-video-wrapper > .play-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 40px;
    cursor: pointer;
    z-index: 10;
    color: white;
}

.carousel-video-wrapper:before {
    content: "";
    background: rgb(255, 255, 255);
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 40%, rgba(0, 0, 0, .78) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#000000", GradientType=1);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    border-radius: 12px;
    left: 0;
    z-index: 2;
}

#event-video-box{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    max-width: 650px;
    max-height: 500px;
    background-color: black;
    border: 5px solid #171717;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

#video-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    max-width: 700px;
    max-height: 500px;
    background-color: black;
    border: 5px solid #171717;
    border-bottom: 5px solid white !important;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

#video-box > div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.close-icon {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    font-size: 40px;
    color: white;
}

.previousEvent-marquee{
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    /*background-color: #ff0000;*/
    padding: 10px 0;
    margin-bottom: 30px;
}
.previousEvent-marquee-content{
    display: inline-flex;
    /*animation: scroll 25s linear infinite;*/
}
@keyframes scroll {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-50%);
    }
}

.carousel-video-wrapper figure {
    margin-bottom: 0;
    position: relative;
    overflow: hidden;
    border-radius: 12px;
    height: 100%;
    width: 100%;
}

.carousel-video-wrapper > figure > img {
    height: inherit;
    transition: .4s all linear;
}

.carousel-video-wrapper > figcaption {
    position: absolute;
    bottom: 10px;
    z-index: 9;
    font-size: 13px;
    left: 15px;
    color: #fff;
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
    .min-box-height {
        min-height: 527px;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1400px) {
    .min-box-height {
        min-height: 555px;
    }
}

@media screen and (min-width: 1400px) {
    .min-box-height {
        min-height: 602px;
    }
}

@media screen and (max-width: 991px) {
    .about-circle:after {
        display: none;
    }
}

.video-iframe iframe {
    aspect-ratio: 3/2;
}

.unfield-button{
    padding: 10px 30px;
    border: none;
    position: relative;
    background-color: transparent;
    border-radius: 30px;
    margin-top: 10px;
    transition: 0.3s;
    border: 2px solid #0AA555;
}
.unfield-button:hover{
    background-color: #0AA555;
    color: white;
}
.unfield-button:disabled{
    border: none;
}